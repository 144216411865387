import { useState, useEffect } from 'react'
import { supabase } from './_shared/supabaseClient'
import Link from './components/Link'

import { supabaseUrl, supabaseAnonKey } from './_shared/supabaseClient'
import { AuthSession } from '@supabase/supabase-js';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

interface AccountProps {
    session: AuthSession;
}

const Account: React.FC<AccountProps> = (props: AccountProps) => {
  const [loading, setLoading] = useState<boolean> (true)
  const [username, setUsername] = useState<string | null> (null)
  const [accounts, setAccounts] = useState<string[] | null > (null)
  const [linkToken, setLinkToken] = useState (null);

  const [open, setOpen] = useState(false);

  const generateToken = async () => {
    const response = await fetch(`${supabaseUrl}/functions/v1/create-link-token`, {
      headers: {
        "Authorization": `Bearer ${supabaseAnonKey}`
      },
    });
    const data = await response.json();
    setLinkToken(data.link_token);
    localStorage.setItem("link_token", data.link_token);
  };

  useEffect(() => {
    generateToken();
  }, [])

  useEffect(() => {
    async function getProfile() {
      setLoading(true)
      const { user } = props.session
      console.log(user);
      const { data, error } = await supabase
        .from('profiles')
        .select(`
            username,
            accounts (name, mask, transactions_count)
        `)
        .single()
      
        console.log(data);

      if (error) {
        console.warn(error)
      } else if (data) {
        setUsername(data.username)
        
        if ( data.accounts.length > 0 ){
          setAccounts(data.accounts.reduce<string[]>( 
            (results, current) => [...results, `${current.name} [***${current.mask}] - ${current.transactions_count} transactions available`],
            []
          ));
        }
      }
      setLoading(false)
    }

    getProfile()
  }, [props.session])

  async function updateProfile(event:any) {
    event.preventDefault()

    setLoading(true)
    const { user } = props.session

    const updates = {
      uid: user.id,
      username,
      updated_at: new Date(),
    }

    const { error } = await supabase.from('profiles').upsert(updates)

    if (error) {
      alert(error.message)
    } else {
      // setAvatarUrl(avatarUrl)
    }
    setLoading(false)
  }

  return (
    <form onSubmit={updateProfile} className="form-widget">
      <div>
        <label htmlFor="email">Email</label>
        <input id="email" type="text" value={props.session.user.email} disabled />
      </div>
      <div>
        <label htmlFor="username">Username</label>
        <input
          id="username"
          type="text"
          required
          value={username || ''}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="username">Accounts linked</label>
        <input
          id="username"
          type="text"
          disabled
          value={accounts || ''}
        />
      </div>

      <List>
      <ListItem
          nested
          sx={{ my: 1 }}
          startAction={
            <IconButton
              variant="plain"
              size="sm"
              color="neutral"
              onClick={() => setOpen(!open)}
            >
              <KeyboardArrowDown
                sx={{ transform: open ? 'initial' : 'rotate(-90deg)' }}
              />
            </IconButton>
          }
        >
          <ListItem>
            <Typography
              level="inherit"
              sx={{
                fontWeight: open ? 'bold' : undefined,
                color: open ? 'text.primary' : 'inherit',
              }}
            >
              Tutorial
            </Typography>
            <Typography component="span" level="body-xs">
              9
            </Typography>
          </ListItem>
          {open && (
            <List sx={{ '--ListItem-paddingY': '8px' }}>
              <ListItem>
                <ListItemButton>Overview</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  0. Set Up Your Development Environment
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  1. Create and Deploy Your First Gatsby Site
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>2. Use and Style React components</ListItemButton>
              </ListItem>
            </List>
          )}
        </ListItem>
      </List>

      {/* { session  ? <PlaidLink session={session} /> : null } */}
      {linkToken != null ? <Link linkToken={linkToken} session={props.session} /> : <></>}

      <div>
        <button className="button block primary" type="submit" disabled={loading}>
          {loading ? 'Loading ...' : 'Update user'}
        </button>
      </div>

      <div>
        <button className="button block" type="button" onClick={() => supabase.auth.signOut()}>
          Sign Out
        </button>
      </div>
    </form>
  )
}

export default Account;