import { createClient } from '@supabase/supabase-js'
import { env } from './settings'

// const SUPABASE_URL="https://hbjpxenzsvibjeywaehb.supabase.co"
// const SUPABASE_ANON_KEY="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhianB4ZW56c3ZpYmpleXdhZWhiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg2Nzk2OTUsImV4cCI6MjAxNDI1NTY5NX0.Z2yK0DTNZZrVw2eECDO21kKD8IZakTDP6O0pmtJs6nY"

export const supabaseUrl = env.dev.SUPABASE_URL
export const supabaseAnonKey = env.dev.SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

