// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>et proche de ce cite aussi
//       </header>
//     </div>
//   );
// }

// export default App;


import './App.css'
import '@fontsource/inter';
import { useState, useEffect } from 'react'
import { supabase } from './_shared/supabaseClient'
import { AuthSession } from '@supabase/supabase-js'
import Auth from './Auth'
import Account from './Account'

const App: React.FC = () => {
  const [session, setSession] = useState< AuthSession | null >(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {      
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return (
    <div className="container" style={{ padding: '50px 0 100px 0' }}>
      { !session ? <Auth /> : null } 
      { session  ? <Account session={session} /> : null }
    </div>
  )
}

export default App