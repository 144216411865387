import { useState } from 'react'
import { supabase } from './_shared/supabaseClient'

const Auth: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (event : any) => {
    event.preventDefault()

    setLoading(true)
    const { error } = await supabase.auth.signInWithOtp({ email })

    if (error) {
      alert(error)
    } else {
      alert('Check your email for the login link!')
    }
    setLoading(false)
  }

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget">
        <h1 className="header">Rootstonegroup POC</h1>
        <p className="description">Sign in via magic link with your email below</p>
        <form className="form-widget" onSubmit={handleLogin}>
          <div>
            <input
              className="inputField"
              type="email"
              placeholder="Your email"
              value={email}
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <button className={'button block'} disabled={loading}>
              {loading ? <span>Loading</span> : <span>Send magic link</span>}
            </button>
          </div>
          <p className="description">Success criteria: 
          <ul>
            <li>Basic user creation & update</li>
            <li>Data access restricted on user uid (CRUD for profiles, items and accounts tables).</li>
            <li>Users can link any of their accounts via Plaid link</li>
            <li>Account data is made available for backend fetch (Manual for now)</li>
          </ul>
        </p>
        <p className="description">Out of scope: 
          <ul>
            <li>Automated transaction fetch for linked accounts</li>
            <li>Data collection via webhook for on-time txs fetch</li>
            <li>Transaction data remapping</li>
            <li>Use of GraphQL queries to minimize internal API chattiness</li>
            <li>Account deduplication across items</li>
            <li>Data mining & Reporting</li>
          </ul>
        </p>
        </form>
      </div>
    </div>
  )
}

export default Auth;