// LINK COMPONENT

// Use Plaid Link and pass link token and onSuccess function

// in configuration to initialize Plaid Link
import { AuthSession } from '@supabase/supabase-js';
import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import { supabaseUrl } from '../_shared/supabaseClient';

interface LinkProps {

  linkToken: string | null;
  session: AuthSession;

}

const Link: React.FC<LinkProps> = (props: LinkProps) => {

  const onSuccess = React.useCallback((public_token : string) => {

    // send public_token to server

    const response = fetch(`${supabaseUrl}/functions/v1/exchange-public-token`, {

      method: 'POST',

      headers: {

        'Content-Type': 'application/json',
        "Authorization": `Bearer ${props.session.access_token}`

      },

      body: JSON.stringify({ public_token }),

    });

    // Handle response ...

  }, []);

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {

    token: props.linkToken!,

    // receivedRedirectUri: window.location.href,

    onSuccess,

  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  return (

    <button onClick={() => open()} className='button block primary' disabled={!ready}>

      Link account

    </button>

  );

};

export default Link;